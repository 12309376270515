var render = function () {
  var _vm$autentiData, _vm$autentiData2, _vm$autentiData3, _vm$autentiData4, _vm$autentiData5, _vm$autentiData6, _vm$autentiData7, _vm$autentiData8, _vm$autentiData9, _vm$autentiData10, _vm$annexingProducts, _vm$activeProduct, _vm$activeProduct2, _vm$activeProduct3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['add-new-product', _vm.$route.name == 'addNewProductSuccess' ? 'margin-top' : 0]
  }, [_vm.$route.name != 'addNewProductSuccess' ? _c('div', {
    staticClass: "add-new-product__actions"
  }, [_c('div', {
    staticClass: "add-new-product__actions-block mr-auto"
  }, [_c('GenproxButton', {
    attrs: {
      "outline": true,
      "icon": _vm.ChevronLeftIcon
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Go back")]), _c('portal-target', {
    attrs: {
      "name": "productSummaryBackBtn"
    }
  })], 1), _vm.isProductSummary && ((_vm$autentiData = _vm.autentiData) !== null && _vm$autentiData !== void 0 && _vm$autentiData.productStatus || (_vm$autentiData2 = _vm.autentiData) !== null && _vm$autentiData2 !== void 0 && _vm$autentiData2.autentiStatus || (_vm$autentiData3 = _vm.autentiData) !== null && _vm$autentiData3 !== void 0 && _vm$autentiData3.eSigned) ? _c('div', {
    staticClass: "statuses"
  }, [(_vm$autentiData4 = _vm.autentiData) !== null && _vm$autentiData4 !== void 0 && _vm$autentiData4.productStatus ? _c('div', {
    staticClass: "statuses__single",
    attrs: {
      "id": "fe_product_status"
    }
  }, [_c('p', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'PRODUCT STATUS',
      expression: "'PRODUCT STATUS'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label"
  }, [_vm._v("PROD. STATUS")]), _c('sygni-rounded-button', {
    class: "add-new-product__status outline round pl-4 pr-4 ".concat(_vm.statusClass((_vm$autentiData5 = _vm.autentiData) === null || _vm$autentiData5 === void 0 ? void 0 : _vm$autentiData5.productStatus)),
    attrs: {
      "hoverable": false
    }
  }, [_vm._v(_vm._s(_vm.statusText((_vm$autentiData6 = _vm.autentiData) === null || _vm$autentiData6 === void 0 ? void 0 : _vm$autentiData6.productStatus)))])], 1) : _vm._e(), (_vm$autentiData7 = _vm.autentiData) !== null && _vm$autentiData7 !== void 0 && _vm$autentiData7.autentiStatus || (_vm$autentiData8 = _vm.autentiData) !== null && _vm$autentiData8 !== void 0 && _vm$autentiData8.eSigned ? _c('div', {
    staticClass: "statuses__single"
  }, [_c('p', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: 'E-SIGNATURE STATUS',
      expression: "'E-SIGNATURE STATUS'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label"
  }, [_vm._v("E-SIGN. STATUS")]), _c('div', {
    staticClass: "status d-flex justify-content-center align-items-center"
  }, [_c('sygni-rounded-button', {
    class: "outline round ".concat(_vm.autentiStatusClass(_vm.autentiData)),
    attrs: {
      "id": "autentiStatus",
      "hoverable": false
    }
  }, [_vm._v(" " + _vm._s(_vm.autentiStatusText(_vm.autentiData)))]), (_vm$autentiData9 = _vm.autentiData) !== null && _vm$autentiData9 !== void 0 && _vm$autentiData9.errorMessage ? _c('b-tooltip', {
    attrs: {
      "boundary": "window",
      "placement": "bottom",
      "target": "autentiStatus",
      "triggers": "hover"
    }
  }, [_vm._v(" " + _vm._s((_vm$autentiData10 = _vm.autentiData) === null || _vm$autentiData10 === void 0 ? void 0 : _vm$autentiData10.errorMessage) + " ")]) : _vm._e()], 1)]) : _vm._e(), _c('portal-target', {
    attrs: {
      "name": "productSummaryStatuses"
    }
  })], 1) : _vm._e()]) : _vm._e(), (_vm$annexingProducts = _vm.annexingProducts) !== null && _vm$annexingProducts !== void 0 && _vm$annexingProducts.length && (_vm$activeProduct = _vm.activeProduct) !== null && _vm$activeProduct !== void 0 && _vm$activeProduct.annexType && _vm.$route.name !== 'addNewProductSuccess' ? _c('div', {
    staticClass: "infobox"
  }, [_c('p', {
    staticClass: "infobox__label"
  }, [_vm.mode === 'edit' ? [_vm._v(" This is " + _vm._s(((_vm$activeProduct2 = _vm.activeProduct) === null || _vm$activeProduct2 === void 0 ? void 0 : _vm$activeProduct2.annexType) === 'annex' ? 'an annex' : 'a roll') + " of: ")] : _vm._e(), _vm.mode === 'add' ? [_vm._v(" You are currently " + _vm._s(((_vm$activeProduct3 = _vm.activeProduct) === null || _vm$activeProduct3 === void 0 ? void 0 : _vm$activeProduct3.annexType) === 'annex' ? 'annexing' : 'rolling') + ": ")] : _vm._e()], 2), _c('ul', {
    staticClass: "infobox__list"
  }, _vm._l(_vm.annexingProducts, function (product) {
    return _c('li', {
      key: product.id
    }, [_c('router-link', {
      attrs: {
        "to": _vm.getProductLink(product.id),
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(product.code))])], 1);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "add-new-product__box"
  }, [_c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }